














import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import ExchangeRate from '@/store/entities/preferences/exchangerate';
import ListView from '@/components/helper/list-view.vue';
import ModalView from '@/components/helper/modal-view.vue';

@Component({
    components: { ListView, ModalView }
})
export default class Series extends ListBase {
    entity: string = 'exchangerate';
    exchangerate: ExchangeRate = new ExchangeRate();
    modalSetting: any = {
        entity: "exchangerate",
        title: 'Tasa de Cambio',
        width: "300px"
    };

    items = [
        this.gItem({ type: 'date', prop: 'process_date', label: 'Fecha', readonly: true, outlined: false }),
        this.gItem({ type: 'number', prop: 'rate', label: 'Tasa', prefix: '#', required: true }),
    ]

    columns = [
        this.gCol('process_date', 'Fecha', 'date', '', false),
        this.gCol('rate', 'Tasa', 'text', '', false),
        this.defaultActions('edit', 80), 
    ]

    afterSave() {
        this.$emit('save-success');
    }        
            
    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    }
    
    async created() {
        
    }
}
