








import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import Currency from '@/store/entities/preferences/currency';
import RatesList from './rates.vue';

@Component({
    components: { ModalView, RatesList },
    data() {
        return {
            modalShow: false
        }
    },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.currency = Util.extend(true, {}, this.$store.state.currency.editModel);                    
                obj.initForm();
            }
        },
        modalShow: function (val) {
            if (!val) {
                this.$emit('input', false);
            }
        }
    }
})

export default class CurrencyForm extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    @Prop({ type: Boolean, default: true }) showDialog: boolean;
    currency: Currency = new Currency();
    modalSetting: any = {
        entity: "currency",
        title: 'Moneda',
        width: "700px",
    };

    items = [
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre', required: true }),
        this.gItem({ type: 'text', prop: 'iso_code', label: 'Codigo Iso', required: true, size: 4 }),
        this.gItem({ type: 'text', prop: 'iso_code_num', label: 'Numero Iso', size: 4 }),
        this.gItem({ type: 'text', prop: 'sign', label: 'Simbolo', required: true, size: 4 }),
        this.gItem({ type: 'number', prop: 'conversion_rate', label: 'Tasa', size: 4 }),
        this.gItem({ type: 'switch', prop: 'decimals', label: 'Decimales', size: 4 }),
        this.gItem({ type: 'active', size: 4 })
    ]
                      
    get showSeries() {
        return this.$store.state.currency.editModel.id > 0;
    };

    async initForm() {           
        if (this.showSeries) {
            this.$store.commit('exchangerate/setCurrency', this.currency.id);
            this.setItem(this.items, 'code', 'disabled', true);

            await this.getRates();

        } else {
            this.setItem(this.items, 'code', 'disabled', false);
        }
    }

    async getRates() {
        await this.$store.dispatch({
            type: 'exchangerate/getLastRates',
            id: this.currency.id
        });
    }

    async afterSaveRate() {
        await this.getRates();
        let rate = this.$store.state.exchangerate.list[0];
        (this.$refs.form as any).model.conversion_rate = rate.rate;
    }

    async afterSave() {
        this.$emit('save-success');
        this.$emit('input', false);
    }

    cancel() {
        this.$emit('input', false);
    }
    
}
