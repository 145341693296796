




















import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ListView from '@/components/helper/list-view.vue';
import CurrencyForm from './currency-form.vue';

class PageCurrencyRequest extends PageRequest { }

@Component({
    components: { ListView, CurrencyForm }
})
export default class Currencies extends ListBase {
    pagerequest: PageCurrencyRequest = new PageCurrencyRequest();
    
    columns = [
        this.gCol('name', 'Nombre', 'text'),
        this.gCol('iso_code', 'Codigo Iso', 'text'),
        this.gCol('iso_code_num', 'Numero Iso', 'text'),
        this.gCol('sign', 'Simbolo', 'text'),
        this.gCol('last_rate', 'Tasa', 'text'),
        this.gCol('active', 'Habilitado', 'boolean'),
        this.defaultActions()
    ]

    get exluddeIds() {
        let ids = [Util.abp.setting.get('DEFAULT_CURRENCY')];
        return { edit: ids, delete: ids };
    }

    get loadingRate() : boolean {
        return this.$store.state.exchangerate.loading;
    }

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    }
    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    }  
    async updateRate() {
        await this.$store.dispatch({ type: 'exchangerate/updateLastRate' });
        await this.$store.dispatch({ type: 'currency/getAllActive', data: {force: true}});
    }  

    async created() {
        //this.getpage();
    }
}